const keys = [
    {
        code: "a",
        src: "note_a"
    },
    {
        code: "s",
        src: "note_b"
    },
    {
        code: "d",
        src: "note_c"
    },
    {
        code: "f",
        src: "note_d"
    },
    {
        code: "g",
        src: "note_e"
    },
    {
        code: "h",
        src: "note_f"
    },
    {
        code: "j",
        src: "note_g"
    },
    {
        code: "k",
        src: "note_h"
    },
]

export default keys;